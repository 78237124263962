import React from "react";
import { connect } from "react-redux";
import { getReferralData } from "../redux/actions/userActions";
import { appConfig } from "../data/appConfig";
import Loading from "../components/Loading";
import NavigationHeader from "../components/NavigationHeader";
import LinkIcon from "../icons/LinkIcon";
import CheckmarkIcon from "../icons/CheckmarkIcon";
import Table from "../components/Table";
import "../styles/DisplayCanvas.css";
import "../styles/InfoPage.css";
import "../styles/Referrals.css";

class AffiliatesStatsPage extends React.Component {
  state = {
    linkCopied: false,
    referralUrl: "",
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    const { userData } = this.props;
    this.props.getReferralData();
    this.setState({
      referralUrl: `${appConfig.referralUrl}?ref=${userData.ReferrerId}`,
    });
  }

  onLinkCopy = () => {
    navigator.clipboard.writeText(this.state.referralUrl);
    this.setState({ linkCopied: true });
    setTimeout(() => this.setState({ linkCopied: false }), 3000);
  };

  render() {
    const { userData, referralData, ReferralDataLoaded, geoLocationData } =
      this.props;
    // console.log("referralData", referralData);
    // console.log("referralDataLoaded", ReferralDataLoaded);

    //  const referralUrl = `${appConfig.referralUrl}?ref=${userData.ReferrerId}`;

    const { referralUrl } = this.state;

    const currency = userData.DefaultCurrency
      ? userData.DefaultCurrency
      : geoLocationData.currency.toLowerCase();
    // console.log(currency);

    const currencySymbol =
      currency === "gbp"
        ? "£"
        : currency === "aud"
        ? "A$"
        : currency === "eur"
        ? "€"
        : currency === "usd"
        ? "$"
        : "$";

    if (!ReferralDataLoaded) {
      return <Loading LoadingCanvas={"loading-page-header-canvas"} />;
    }

    if (ReferralDataLoaded) {
      const customerReferralData = referralData.customerReferrals.map(
        (item) => {
          return {
            PropertyTitle: item.PropertyTitle,
            RewardAmount: `${currencySymbol}${item.RewardAmount}`,
            Status: item.RewardCredited
              ? "Confirmed, reward payment completed"
              : item.RewardClaimable && !item.RewardCredited
              ? "Confirmed, you can now claim your reward"
              : item.ActiveSubscriber &&
                !item.RewardClaimable &&
                !item.RewardCredited
              ? "Subscribed. First month not completed yet"
              : !item.ActiveSubscriber &&
                !item.RewardCredited &&
                item.TrialEndDate > new Date()
              ? "In trial period, not subscribed yet"
              : !item.ActiveSubscriber &&
                !item.RewardCredited &&
                item.TrialEndDate < new Date()
              ? "Trial ended, not subscribed yet"
              : "",
            // RewardLink: item.RewardLink
          };
        }
      );

      const currentRewardValue =
        currency === "gbp"
          ? referralData.currentRewardValueGBP
          : currency === "usd"
          ? referralData.currentRewardValueUSD
          : currency === "aud"
          ? referralData.currentRewardValueAUD
          : currency === "eur"
          ? referralData.currentRewardValueEUR
          : referralData.currentRewardValueUSD;

      // const accountBalanceCalc = -referralData.accountBalance / 100;
      // const accountBalance = accountBalanceCalc.toFixed(2);

      const currentPageTitle = "Referrals";

      return (
        <>
          <NavigationHeader
            {...this.props}
            previousPageTitle={"Dashboard"}
            previousPagePath={"/"}
            currentPageTitle={currentPageTitle}
          />
          <div className="canvas-with-header-navheader-and-footer">
            <div className="canvas-with-header-and-footer-inner-container">
              <div className="h1-header-large"> {currentPageTitle}</div>

              <div className="mid-section-wrapper">
                <div className="stats-section-callout-banner-wrapper">
                  <div className="stats-section-callout-banner">
                    <div className="stats-section-callout-banner-inner">
                      <div className="stats-section-callout-banner-header">
                        Total Link<br></br>Clicks
                      </div>
                      <div className="stats-section-callout-banner-value">
                        {referralData.totalLinkClicks
                          ? referralData.totalLinkClicks
                          : 0}
                      </div>
                    </div>
                  </div>

                  <div className="stats-section-callout-banner-spacer"></div>

                  <div className="stats-section-callout-banner">
                    <div className="stats-section-callout-banner-inner">
                      <div className="stats-section-callout-banner-header">
                        Total Confirmed<br></br>Referrals
                      </div>
                      <div className="stats-section-callout-banner-value">
                        {referralData.totalReferrals}
                      </div>
                    </div>
                  </div>

                  <div className="stats-section-callout-banner-spacer"></div>

                  <div className="stats-section-callout-banner">
                    <div className="stats-section-callout-banner-inner">
                      <div className="stats-section-callout-banner-header">
                        Total Commission<br></br>Earned
                      </div>
                      <div className="stats-section-callout-banner-value">
                        <span>{currencySymbol}</span>
                        {referralData.totalRewards
                          ? referralData.totalRewards
                          : 0}
                      </div>
                    </div>
                  </div>
                </div>

                {customerReferralData.length === 0 && (
                  <>
                    <div className="sub-section-text">
                      No referrals yet - tracked referrals will be displayed
                      here
                    </div>
                  </>
                )}

                {customerReferralData.length > 0 && (
                  <Table
                    {...{
                      data: customerReferralData,
                      fields: {
                        PropertyTitle: "Property Name",
                        RewardAmount: "Reward Amount",
                        Status: "Status",
                        // RewardLink: "Reward Link",
                      },
                    }}
                  />
                )}

                <div className="section-break-wrapper">
                  <div className="section-break"></div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getReferralData: () => dispatch(getReferralData()),
  };
};

export default connect(null, mapDispatchToProps)(AffiliatesStatsPage);
