import React from "react";
import { connect } from "react-redux";
import { getReferralData } from "../redux/actions/userActions";
import { appConfig } from "../data/appConfig";
import Loading from "../components/Loading";
import NavigationHeader from "../components/NavigationHeader";
import LinkIcon from "../icons/LinkIcon";
import CheckmarkIcon from "../icons/CheckmarkIcon";
import "../styles/DisplayCanvas.css";
import "../styles/InfoPage.css";
import "../styles/Referrals.css";

class AffiliatesTermsPage extends React.Component {
  state = {
    linkCopied: false,
    referralUrl: "",
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    const { userData } = this.props;
    this.props.getReferralData();
    this.setState({
      referralUrl: `${appConfig.referralUrl}?ref=${userData.ReferrerId}`,
    });
  }

  onLinkCopy = () => {
    navigator.clipboard.writeText(this.state.referralUrl);
    this.setState({ linkCopied: true });
    setTimeout(() => this.setState({ linkCopied: false }), 3000);
  };

  render() {
    const { userData, referralData, ReferralDataLoaded, geoLocationData } =
      this.props;
    // console.log("referralData", referralData);
    // console.log("referralDataLoaded", ReferralDataLoaded);
    //  const referralUrl = `${appConfig.referralUrl}?ref=${userData.ReferrerId}`;

    const { referralUrl } = this.state;

    const currency = userData.AffiliateCommissionCurrency
      ? userData.AffiliateCommissionCurrency
      : geoLocationData.currency.toLowerCase();
    // console.log(currency);

    const currencySymbol =
      currency === "gbp"
        ? "£"
        : currency === "aud"
        ? "A$"
        : currency === "eur"
        ? "€"
        : currency === "usd"
        ? "$"
        : "$";

    if (!ReferralDataLoaded) {
      return <Loading LoadingCanvas={"loading-page-header-canvas"} />;
    }

    if (ReferralDataLoaded) {
      const currentPageTitle = "Guidey Affiliate Program";

      return (
        <>
          <NavigationHeader
            {...this.props}
            previousPageTitle={"Dashboard"}
            previousPagePath={"/"}
            currentPageTitle={currentPageTitle}
          />
          <div className="canvas-with-header-navheader-and-footer">
            <div className="canvas-with-header-and-footer-inner-container">
              <div className="h1-header-large"> {currentPageTitle}</div>
              <div className="top-section-wrapper">
                <div className="top-section-detail-snippet">
                  <div className="section-subheading">
                    {userData.AffiliateCommissionModel === "fixed" && (
                      <>
                        Promote us to your audience and earn{" "}
                        <span>
                          <strong>
                            {currencySymbol}
                            {userData.AffiliateCommissionAmount}
                          </strong>
                        </span>{" "}
                        commission for each customer!
                      </>
                    )}
                    {userData.AffiliateCommissionModel === "percentage" && (
                      <>
                        Promote us to your audience and earn{" "}
                        <span>
                          <strong>
                            {userData.AffiliateCommissionPercentage}
                            {"%"}
                          </strong>
                        </span>{" "}
                        commission for {userData.AffiliateCommissionMonths}{" "}
                        months on each sale!
                      </>
                    )}
                  </div>

                  <div className="sub-section-text">
                    <strong>1.</strong> Simply share your unique referral link
                    below.
                  </div>

                  <div className="sub-section-text">
                    <strong>2.</strong> Anyone who signs up from your link will
                    be automatically tracked.
                  </div>

                  <div className="sub-section-text">
                    <strong>3.</strong> Shortly after they’ve completed their
                    first paid month, we will issue your commission and let you
                    know via email!
                  </div>
                </div>
              </div>

              <div className="url-callout-banner">
                <div className="url-callout-banner-header">
                  👇 <strong>Share Your Link</strong> 👇
                </div>

                {
                  //    <div className="url-callout-banner-social-buttons-container">
                  //    <a
                  //      href={`https://api.whatsapp.com/send?text=${referralUrl}`}
                  //     target="_blank"
                  //      rel="noopener"
                  //   >
                  //     <button>WhatsApp Share</button>
                  //   </a>
                  //  </div>
                }

                <a
                  className="url-callout-banner-value"
                  id="infopage-link"
                  href={referralUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {referralUrl}
                </a>

                <button
                  className="referral-link-button"
                  onClick={this.onLinkCopy}
                >
                  {!this.state.linkCopied && (
                    <>
                      <div className="referral-link-label">Copy link</div>

                      <LinkIcon className="referral-link-box-icon" />
                    </>
                  )}

                  {this.state.linkCopied && (
                    <>
                      <div className="referral-link-label">
                        Link copied to clipboard
                      </div>
                      <CheckmarkIcon className="referral-link-box-icon" />
                    </>
                  )}
                </button>
              </div>

              <div className="mid-section-wrapper">
                <div className="section-break-wrapper">
                  <div className="section-break"></div>
                </div>

                <div className="sub-section-text">
                  <strong>Affiliate Program Terms and Conditions </strong>
                  <ol>
                    <li>
                      The affiliate commission ('commission') will be issued for
                      each new, legitimate paying user referred to us through
                      your unique affiliate link ('referred customer'). Users
                      with multiple properties count as 1 referred customer. The
                      referred customer must hold a paid subscription for at
                      least 35 days.
                    </li>
                    <li>
                      The commission amount will be paid monthly as a percentage
                      of the referred customer's monthly subscription amount at
                      the percentage rate, and for the duration advertised when
                      the referred customer created their account.
                    </li>
                    <li>
                      There is no limit to how much commission you can earn.
                      However, we reserve the right not to issue commission, or
                      to revoke commission if we deem that the promotion has
                      been abused. In such a case where we have already paid
                      commission, we reserve the right to withhold future
                      commission.
                    </li>
                    <li>
                      Commission must be claimed within 90 days of being issued.
                      Within this period, commission remains claimable whilst
                      your account is a live. Commission is not exchangeable for
                      any other form of reward or access to our service.
                    </li>
                    <li>
                      We reserve the right to revoke commission if you close
                      your account or if we are required to revoke your access
                      to our service for any reason.
                    </li>
                    <li>
                      By using your Guidey Affiliate Account and/or sharing your
                      affiliate link, you agree to these terms and conditions.
                    </li>
                    <li>
                      If you have any questions please contact us at
                      affiliates@guideyapp.com
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getReferralData: () => dispatch(getReferralData()),
  };
};

export default connect(null, mapDispatchToProps)(AffiliatesTermsPage);
