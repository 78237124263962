import React from "react";
import NavigationHeader from "../components/NavigationHeader";
import "../styles/DisplayCanvas.css";
import "../styles/InfoPage.css";

export default class AffiliatesAssetsPage extends React.Component {
  // Scroll to top on page load
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const currentPageTitle = "Media Assets";

    return (
      <>
        <NavigationHeader
          {...this.props}
          previousPageTitle={"Dashboard"}
          previousPagePath={"/"}
          currentPageTitle={currentPageTitle}
        />
        <div className="canvas-with-header-navheader-and-footer">
          <div className="canvas-with-header-and-footer-inner-container">
            <div className="h1-header-large"> {currentPageTitle}</div>
            <div className="infopage-subheading">
              We've provided a selection of marketing materials that can be used
              to promote us to your audience.
            </div>

            <div className="section-break-wrapper">
              <div className="section-break"></div>
            </div>

            <h3> Logos </h3>

            <a
              href="https://cdn.guidey.app/system/affiliate-assets/guidey-logo.png"
              download="guidey-logo.png"
              target="_blank"
            >
              <img
                className="infoitem-image"
                src="https://cdn.guidey.app/system/affiliate-assets/guidey-logo.png"
                alt="Guidey Logo"
              />
            </a>

            <div className="section-break-wrapper">
              <div className="section-break"></div>
            </div>

            <h3> Short Description </h3>
            <div className="sub-section-text">
              Guidey is the #1 digital guidebook for short term rental hosts.
            </div>

            <div className="section-break-wrapper">
              <div className="section-break"></div>
            </div>

            <h3> Long Description </h3>
            <div className="sub-section-text">
              <strong>Digital guidebooks your guests will love</strong>
            </div>
            <div className="sub-section-text">
              Guidey is the #1 digital guidebook, designed to streamline hosting
              for property managers. It provides guests with all the essential
              information about their accommodation and the local area in one
              convenient web app, making it easier to enhance the guest
              experience and leave a lasting impression.
            </div>
            <div className="sub-section-text">
              <h4>Happy Host</h4>
              <ul>
                <li>Save time, eliminate guest questions</li>
                <li>Quickly update information, anytime</li>
                <li>Appear more professional</li>
              </ul>
              <h4>Happy Guest</h4>
              <ul>
                <li>Immediate answers to pressing questions</li>
                <li>Convenience and speed</li>
                <li>Access to information in advance</li>
              </ul>
              <h4>Industry-leading Features</h4>
              All included from the moment you start your free trial:
              <ul>
                <li>Unlimited video, image, and PDF uploads</li>
                <li>Unlimited personalized local area recommendations</li>
                <li>Custom web link URLs to share with your guests</li>
                <li>Custom QR codes to print and display in your properties</li>
                <li>
                  Works on all mobile, desktop, and tablet devices, with nothing
                  for guests to download
                </li>
                <li>
                  Advanced location technology integration - drag map pins or
                  use what3words to guide guests to precise locations
                </li>
                <li>
                  Multi-language automatic translations into over 100 languages
                </li>
                <li>Passcode functionality to keep your apps secure</li>
                <li>
                  Publish or hide your app easily with a single button click
                </li>
                <li>Customer support to help you get set up</li>
                <li>
                  No Ads or sponsored recommendations - just your own content
                </li>
                <li>
                  Easily apply your brand logo and colors to unlimited, multiple
                  properties
                </li>
                <li>
                  Fully scalable solution works across unlimited property
                  listings
                </li>
                <li>
                  Industry-leading functionality to create content once and
                  share across unlimited, multiple properties
                </li>
                <li>Analytics to see how guests are using your apps</li>
              </ul>
            </div>
            <div className="sub-section-text">
              <h4>The #1 Choice for Professional Hosts</h4>
              “Brilliant. Couldn’t do without it. Makes my life as a host so
              much easier... Very easy to personalize for my own business, I can
              adapt it easily to what we offer guests.”
            </div>
            <div className="sub-section-text">
              “airguide app has made our hosting much simpler, leaving guests
              free to enjoy their break and us free too! The app details
              everything our guests need to know... It has ended the calls we
              have from guests as everything is in the app!”
            </div>
            <div className="sub-section-text">
              “It’s incredibly easy to use and a wonderful way to give your
              guests up-to-date, easily accessible information and things to do
              locally.”
            </div>
          </div>
        </div>
      </>
    );
  }
}
