import React from "react";
import NavigationHeader from "../components/NavigationHeader";
import InfoItemRadioSingle from "../components/InfoItemRadioSingle";
import InfoItemOneBox from "../components/InfoItemOneBox";
import "../styles/DisplayCanvas.css";
import "../styles/InfoPage.css";

export default class AffiliatesPaymentDetailsPage extends React.Component {
  // Scroll to top on page load
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { SaveStatus, userData } = this.props;

    const currentPageTitle = "Payment Details";

    return (
      <>
        <NavigationHeader
          {...this.props}
          previousPageTitle={"Dashboard"}
          previousPagePath={"/"}
          currentPageTitle={currentPageTitle}
        />
        <div className="canvas-with-header-navheader-and-footer">
          <div className="canvas-with-header-and-footer-inner-container">
            <div className="h1-header-large"> {currentPageTitle}</div>
            <div className="infopage-subheading">
              Provide details so we can pay commission to you.
            </div>

            <div className="infoitem-container">
              <div className="section-break-wrapper">
                <div className="section-break"></div>
              </div>
            </div>

            <InfoItemRadioSingle
              SaveStatus={SaveStatus}
              ContainerClassName={"infoitem-form-inner-container-full"}
              userId={userData._id}
              ItemType={"user"} // Defines which dispatch to invoke
              ItemLabel={"affiliate-payment-currency"}
              ItemDescription={
                "This is the currency that your commission will be paid in."
              }
              InputLabelA={""}
              ValueA={
                userData.AffiliateCommissionCurrency
                  ? userData.AffiliateCommissionCurrency
                  : "usd"
              }
              IsChecked={true}
              IsRequired={false}
              ItemHeading={"Payment Currency"}
              FalseText={""}
              PayloadA={"AffiliateCommissionCurrency"}
              radioOptions={[
                { keyLabel: "usd", valueLabel: "US Dollar $" },
                { keyLabel: "gbp", valueLabel: "British Pound £" },
                { keyLabel: "eur", valueLabel: "Euro €" },
                { keyLabel: "aud", valueLabel: "Australian Dollar A$" },
              ]}
            />

            <InfoItemOneBox
              SaveStatus={SaveStatus}
              userId={userData._id}
              ItemType={"user"} // Defines which dispatch to invoke
              ContainerClassName={"infoitem-form-inner-container-full"}
              ItemLabel={"paypal-email-address"}
              ItemHeading={"PayPal Email Address"}
              ItemDescription={
                "This is the PayPal email account we will send your commission payments to."
              }
              InputLabelA={"PayPal email address"}
              ValueA={userData.AffiliatePayPalEmail}
              IsChecked={userData.AffiliatePayPalEmail ? true : false}
              IsRequired={false}
              FalseText={"Not entered"}
              PayloadA={"AffiliatePayPalEmail"}
            />
          </div>
        </div>
      </>
    );
  }
}
