import React from "react";
import "../styles/DisplayCanvas.css";
import "../styles/ButtonBanners.css";
import ButtonBanner from "./ButtonBanner";
import ProfileIcon from "../icons/ProfileIcon";
import PadlockIcon from "../icons/PadlockIcon";
import WalletIcon from "../icons/WalletIcon";
import BadgeIcon from "../icons/BadgeIcon";

export default class DashboardAccount extends React.Component {
  render() {
    const { SectionHeading, MasterAccount } = this.props;
    return (
      <>
        <div className="button-banner-section-outer-container">
          <div className="button-banner-section-container">
            <h3> {SectionHeading} </h3>

            <div className="button-banners-container">
              <div className="button-banner-container-1">
                <ButtonBanner
                  Icon={ProfileIcon}
                  Heading={"Personal Profile"}
                  SubHeading={"Your name and contact details"}
                  Target={"personal-information"}
                  {...this.props}
                />
              </div>

              <div className="button-banner-container-2">
                <ButtonBanner
                  Icon={PadlockIcon}
                  Heading={"Login Details"}
                  SubHeading={"Edit your username and password"}
                  Target={"login-security"}
                  {...this.props}
                />
              </div>

              {MasterAccount && (
                <div className="button-banner-container-3">
                  <ButtonBanner
                    Icon={WalletIcon}
                    Heading={"Subscription and Payment"}
                    SubHeading={"Your subscription and payment details"}
                    Target={"subscription"}
                    {...this.props}
                  />
                </div>
              )}

              {MasterAccount && (
                <div className="button-banner-container-4">
                  <ButtonBanner
                    Icon={BadgeIcon}
                    Heading={"Referrals"}
                    SubHeading={"Refer a friend and earn account credit"}
                    Target={"referrals"}
                    {...this.props}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
