import React from "react";
import NavigationHeader from "../components/NavigationHeader";
import Loading from "../components/Loading";
import InfoItemOneBox from "../components/InfoItemOneBox";
import InfoItemImage from "../components/InfoItemImage";
import InfoItemThemeColours from "../components/InfoItemThemeColours";
import "../styles/DisplayCanvas.css";
import "../styles/InfoPage.css";

export default class CompanyProfilePage extends React.Component {
  // Scroll to top on page load and log pageview
  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  onCreateProfileClick = () => {
    this.props.history.push("/create-company");
  };

  onSharedContentClick = () => {
    this.props.history.push(
      `shared-content/${this.props.userData.SharedContentProfileId}`
    );
  };

  render() {
    const {
      SaveStatus,
      userData,
      companyData,
      // SharedPropertyContentProfileId,
      LinkedManagementCoLoaded,
    } = this.props;

    const currentPageTitle = "Company Profile";

    return (
      <>
        <NavigationHeader
          {...this.props}
          previousPageTitle={"Dashboard"}
          previousPagePath={"/"}
          currentPageTitle={currentPageTitle}
        />
        <div className="canvas-with-header-navheader-and-footer">
          <div className="canvas-with-header-and-footer-inner-container">
            <div className="h1-header-large"> {currentPageTitle}</div>
            <div className="infopage-subheading">
              Provide details about your company to share across multiple
              properties
            </div>

            <div className="infoitem-container">
              <div className="section-break-wrapper">
                <div className="section-break"></div>
              </div>
            </div>

            {LinkedManagementCoLoaded === false && (
              <>
                <Loading
                  LoadingCanvas={"loading-page-header-canvas"}
                  LoadingText={"Creating company profile"}
                />
              </>
            )}

            {companyData[0] && LinkedManagementCoLoaded !== false && (
              <>
                <InfoItemOneBox
                  SaveStatus={SaveStatus}
                  userId={userData._id}
                  companyId={companyData[0]._id}
                  ItemType={"company"} // Defines which dispatch to invoke
                  ContainerClassName={"infoitem-form-inner-container-full"}
                  ItemLabel={"Brand name"}
                  ItemHeading={"Brand name"}
                  ItemDescription={
                    "This is the brand name that's displayed to your guests"
                  }
                  InputLabelA={"Brand name"}
                  ValueA={companyData[0].MgmtCoBrandname}
                  IsChecked={companyData[0].MgmtCoBrandname ? true : false}
                  FalseText={"Not entered"}
                  PayloadA={"MgmtCoBrandname"}
                />

                <InfoItemImage
                  SaveStatus={SaveStatus}
                  userId={userData._id}
                  companyId={companyData[0]._id}
                  ItemType={"company"} // Defines which dispatch to invoke
                  ItemLabel={"company-profile-logo"}
                  FileFolder={"company-content"}
                  ItemId={companyData[0]._id} // Defines which id to insert into filename
                  ItemHeading={"Logo"}
                  ItemDescription={
                    "This is the logo displayed to your guests when your guide loads"
                  }
                  InputLabelA={""}
                  ValueA={companyData[0].MgmtCoLogo}
                  PayloadA={"MgmtCoLogo"} // Defines which database label to update
                  IsChecked={companyData[0].MgmtCoLogo ? true : false}
                  FalseText={"Not uploaded"}
                />

                <InfoItemImage
                  SaveStatus={SaveStatus}
                  userId={userData._id}
                  companyId={companyData[0]._id}
                  ItemType={"company"} // Defines which dispatch to invoke
                  ItemLabel={"company-profile-icon"}
                  FileFolder={"company-content"}
                  ItemId={companyData[0]._id} // Defines which id to insert into filename
                  ItemHeading={"Home Screen Icon"}
                  ItemDescription={
                    "This is the icon displayed when a guest installs your guide to the home screen of their mobile device (square images work best)."
                  }
                  InputLabelA={""}
                  ValueA={companyData[0].HomeScreenIcon}
                  PayloadA={"HomeScreenIcon"} // Defines which database label to update
                  IsChecked={companyData[0].HomeScreenIcon ? true : false}
                  FalseText={"Not uploaded"}
                />

                <InfoItemThemeColours
                  SaveStatus={SaveStatus}
                  userId={userData._id}
                  companyId={companyData[0]._id}
                  ItemType={"company"} // Defines which dispatch to invoke
                  ItemLabel={"Theme colours"}
                  ItemHeading={"Theme colours"}
                  ItemDescription={
                    "Select colours or enter hex codes below (for best results, avoid using white or very light colours)"
                  }
                  InputLabelA={"Icons and buttons"}
                  ValueA={companyData[0].MgmtCoHexPrimary}
                  PayloadA={"MgmtCoHexPrimary"}
                  InputLabelB={"Menu and banners"}
                  ValueB={companyData[0].MgmtCoHexSecondary}
                  PayloadB={"MgmtCoHexSecondary"}
                  IsChecked={
                    companyData[0].MgmtCoHexPrimary &&
                    companyData[0].MgmtCoHexSecondary
                      ? true
                      : false
                  }
                  FalseText={"Not selected"}
                />

                <InfoItemThemeColours
                  SaveStatus={SaveStatus}
                  userId={userData._id}
                  companyId={companyData[0]._id}
                  ItemType={"company-loading"} // Defines which dispatch to invoke
                  ItemLabel={"Loading screen colours"}
                  ItemHeading={"Loading screen colours"}
                  ItemDescription={
                    "Select colours or enter hex codes below (for best results, use two contrasting colours)"
                  }
                  InputLabelA={"Background"}
                  ValueA={companyData[0].BackgroundHex}
                  PayloadA={"BackgroundHex"}
                  InputLabelB={"Text and loading animation"}
                  ValueB={companyData[0].FontHex}
                  PayloadB={"FontHex"}
                  IsChecked={
                    companyData[0].BackgroundHex && companyData[0].FontHex
                      ? true
                      : false
                  }
                  FalseText={"Not selected"}
                />

                <InfoItemOneBox
                  SaveStatus={SaveStatus}
                  userId={userData._id}
                  companyId={companyData[0]._id}
                  ItemType={"company"} // Defines which dispatch to invoke
                  ContainerClassName={"infoitem-form-inner-container-full"}
                  ItemLabel={"company-website"}
                  ItemHeading={"Company website"}
                  ItemDescription={"Your company website"}
                  InputLabelA={"Company website"}
                  ValueA={companyData[0].CompanyWebsite}
                  IsChecked={companyData[0].CompanyWebsite ? true : false}
                  FalseText={"Not entered"}
                  PayloadA={"CompanyWebsite"}
                />

                <InfoItemOneBox
                  SaveStatus={SaveStatus}
                  userId={userData._id}
                  companyId={companyData[0]._id}
                  ItemType={"company"} // Defines which dispatch to invoke
                  ContainerClassName={"infoitem-form-inner-container-full"}
                  ItemLabel={"company-pms"}
                  ItemHeading={"Property management system (PMS)"}
                  ItemDescription={"Your property management system (PMS)"}
                  InputLabelA={"Property management system (PMS)"}
                  ValueA={companyData[0].CompanyPMS}
                  IsChecked={companyData[0].CompanyPMS ? true : false}
                  FalseText={"Not entered"}
                  PayloadA={"CompanyPMS"}
                />

                {
                  // SharedPropertyContentProfileId && (
                  // <button
                  //   className="infoitem-inline-edit-button"
                  //  onClick={this.onSharedContentClick}
                  // >
                  //  {"Shared content >"}
                  // </button>
                  // )
                }
              </>
            )}

            {!companyData[0] && LinkedManagementCoLoaded !== false && (
              <>
                <button
                  className="create-new-button"
                  onClick={this.onCreateProfileClick}
                >
                  Create company profile
                </button>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}
