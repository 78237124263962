import React from "react";
import ReactGA from "react-ga4";
import { connect } from "react-redux";
import { getSubscriptionData } from "../redux/actions/userActions";
import Loading from "../components/Loading";
import CancellationForm from "../components/CancellationForm";
import NavigationHeader from "../components/NavigationHeader";
import "../styles/DisplayCanvas.css";
import "../styles/InfoItem.css";
import "../styles/CreateSubscription.css";

class CancellationPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    const { userEmailId } = this.props;
    ReactGA.send({
      hitType: "pageview",
      page: "/",
      title: `${userEmailId} - Subscription and Payment`,
    });
    this.props.getSubscriptionData();
  }

  componentDidUpdate = () => {
    window.scrollTo(0, 0);
  };

  render() {
    const { StripeDataLoaded } = this.props;

    const currentPageTitle = "Edit or Cancel Your Subscription";

    if (!StripeDataLoaded) {
      return <Loading LoadingCanvas={"loading-page-header-canvas"} />;
    }

    if (StripeDataLoaded) {
      //console.log("stripeData", stripeData);
      return (
        <>
          <NavigationHeader
            {...this.props}
            previousPageTitle={"Subscription and Payment"}
            previousPagePath={"/subscription"}
            currentPageTitle={currentPageTitle}
          />
          <div className="canvas-with-header-navheader-and-footer">
            <div className="canvas-with-header-and-footer-inner-container">
              <div className="h1-header-large"> {currentPageTitle}</div>

              <CancellationForm {...this.props} />
            </div>
          </div>
        </>
      );
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getSubscriptionData: () => dispatch(getSubscriptionData()),
  };
};

export default connect(null, mapDispatchToProps)(CancellationPage);
