import React from "react";
import "../styles/DisplayCanvas.css";
import "../styles/ButtonBanners.css";
import ButtonBanner from "./ButtonBanner";
import BusinessIcon from "../icons/BusinessIcon";
import CameraIcon from "../icons/CameraIcon";
import BadgeIcon from "../icons/BadgeIcon";

export default class AffiliatesStats extends React.Component {
  render() {
    const { SectionHeading } = this.props;
    return (
      <>
        <div className="button-banner-section-outer-container">
          <div className="button-banner-section-container">
            <h3> {SectionHeading} </h3>

            <div className="button-banners-container">
              <div className="button-banner-container-1">
                <ButtonBanner
                  Icon={BadgeIcon}
                  Heading={"Your Referrals"}
                  SubHeading={"Track your referrals"}
                  Target={"affiliate-stats"}
                  {...this.props}
                />
              </div>
              <div className="button-banner-container-2">
                <ButtonBanner
                  Icon={CameraIcon}
                  Heading={"Media Assets"}
                  SubHeading={"How to promote us"}
                  Target={"affiliate-assets"}
                  {...this.props}
                />
              </div>
              <div className="button-banner-container-3">
                <ButtonBanner
                  Icon={BusinessIcon}
                  Heading={"Commission Terms and Conditions"}
                  SubHeading={"How our program works"}
                  Target={"affiliate-terms"}
                  {...this.props}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
