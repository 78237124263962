import React from "react";
import { connect } from "react-redux";
import { googleMapsApiKey, workflowApiRoot } from "../constants.js";
import Script from "react-load-script";
import { createContent } from "../redux/actions/userActions";
import "../styles/InfoItem.css";

class InfoItemPlacesSearch extends React.Component {
  state = {
    payload: this.props.customPlaceContent
      ? this.props.customPlaceContent
      : null,
    lat: this.props.isComponentShared
      ? this.props.sharedLocalAreaContentProfileData.GeoLocationLat
      : this.props.propertyDetails.GeoLocationLat
      ? this.props.propertyDetails.GeoLocationLat
      : 0,
    lng: this.props.isComponentShared
      ? this.props.sharedLocalAreaContentProfileData.GeoLocationLng
      : this.props.propertyDetails.GeoLocationLng
      ? this.props.propertyDetails.GeoLocationLng
      : 0,
    errorMessage: false,
    resetContent: this.props.resetContent,
  };

  handleScriptLoad = () => {
    this.createMap();
  };

  createMap = () => {
    var center = new google.maps.LatLng(this.state.lat, this.state.lng);
    var defaultBounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(this.state.lat - 0.05, this.state.lng - 0.05),
      new google.maps.LatLng(this.state.lat + 0.05, this.state.lng + 0.05)
    );
    // Declare Options For Autocomplete
    var options = {
      location: center,
      bounds: defaultBounds,
      types: ["establishment", "geocode"],
      rankBy: google.maps.places.RankBy.DISTANCE,
    };

    // Initialize Google Autocomplete
    /*global google*/
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById(`${this.props.ItemLabel}-autocomplete`),
      options
    );

    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components and formatted
    // address.
    this.autocomplete.setFields([
      "place_id",
      "name",
      "rating",
      "formatted_address",
      "types",
      "international_phone_number",
      "website",
      "photos",
      "address_components", // Remove?
      "geometry",
    ]);

    // Fire Event when a suggested name is selected
    this.autocomplete.addListener("place_changed", this.handlePlaceSelect);
  };

  handlePlaceSelect = () => {
    const {
      allCustomPlaceContent,
      CategoryLabel,
      userId,
      propertyId,
      SharedContentProfileId,
      linkedProperties,
      isComponentShared,
      ItemLabel,
      customPlaceContent,
    } = this.props;

    // console.log("customPlaceContent", allCustomPlaceContent)
    // Extract City From Address Object
    const addressObject = this.autocomplete.getPlace();
    const address = addressObject.address_components;
    //  console.log("addressObject", addressObject);

    function extractFromAddress(components, type) {
      for (var i = 0; i < components.length; i++)
        for (var j = 0; j < components[i].types.length; j++)
          if (components[i].types[j] === type) return components[i].long_name;
      return "";
    }

    var streetNumber = extractFromAddress(address, "street_number");
    var postCode = extractFromAddress(address, "postal_code");
    var street = extractFromAddress(address, "route");
    var town = extractFromAddress(address, "locality");
    var postalTown = extractFromAddress(address, "postal_town");
    var county = extractFromAddress(address, "administrative_area_level_2");
    var state = extractFromAddress(address, "administrative_area_level_1");
    var country = extractFromAddress(address, "country");

    var customContentCheck = null;
    if (allCustomPlaceContent) {
      customContentCheck = allCustomPlaceContent.filter((Item) => {
        return Item.GooglePlaceID === addressObject.place_id;
      });
    }

    // console.log("customContentCheck", customContentCheck);
    var statePayloadCheck = null;
    if (this.state.payload !== null) {
      statePayloadCheck = this.state.payload.filter((Item) => {
        return Item.GooglePlaceID === addressObject.place_id;
      });
    }

    //  console.log("statePayloadCheck", statePayloadCheck);

    var isNewContent = true;
    if (statePayloadCheck !== null && statePayloadCheck[0]) {
      isNewContent = false;
    }
    if (customContentCheck !== null && customContentCheck[0]) {
      isNewContent = false;
    }
    //  console.log("isNewContent", isNewContent);

    fetch(`${workflowApiRoot}get-place-details`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        place_id: `${addressObject.place_id}`,
        lat: addressObject.geometry.location.lat(),
        lng: addressObject.geometry.location.lng(),
      }),
    })
      .then((response) => response.json())
      //    .then((json) => console.log(json.response.photoReference));

      .then((json) => {
        //  console.log(json.response);
        this.setState({
          photoReference: json.response.photoReference,
          whatThreeWordsLocation: json.response.whatThreeWords,
        });

        // Check if address is valid
        if (address) {
          //  console.log("this.state.payload", this.state.payload)
          //  if (this.state.payload !== null && isNewContent) {
          if (isNewContent) {
            // Create string for DetailLabel
            var detailLabelString = addressObject.name;
            detailLabelString = detailLabelString
              .replace(/[^a-zA-Z0-9]/g, "-")
              .replace(/----/g, "-")
              .replace(/---/g, "-")
              .replace(/--/g, "-")
              .toLowerCase();

            const customPlaceContentCopy = customPlaceContent
              ? customPlaceContent
              : null;
            const lastListItem = customPlaceContentCopy
              ? customPlaceContentCopy.pop()
              : 0;
            const lastListRank = lastListItem ? lastListItem.ListRank : 0;
            //console.log("lastListRank", lastListRank)

            const createContentPayload = {
              GooglePlaceID: addressObject.place_id,
              DetailTitle: addressObject.name ? addressObject.name : "",
              DetailImage: null,
              //   DetailImage: this.state.detailImage,
              //   DetailImage: addressObject.photos
              //   ? addressObject.photos[0].getUrl()
              // : null,
              GooglePhotoReference: this.state.photoReference,
              WhatThreeWordsLocation: this.state.whatThreeWordsLocation,
              GeoLocationAddressLineOne: streetNumber + " " + street,
              GeoLocationAddressLineTwo: "",
              GeoLocationTownCity: postalTown ? postalTown : town,
              GeoLocationCounty: county,
              GeoLocationState: state,
              GeoLocationPostcode: postCode,
              GeoLocationCountry: country,
              GeoLocationFormattedAddress: addressObject.formatted_address
                ? addressObject.formatted_address
                : "",
              GeoLocationLat: addressObject.geometry.location.lat(),
              GeoLocationLng: addressObject.geometry.location.lng(),
              DetailTextDescribe: addressObject.formatted_address,
              DetailTextLocate: "",
              DetailTextOperate: "",
              WebLink: addressObject.website ? addressObject.website : "",
              DetailVideo: null,
              DetailDocument: null,
              GoogleRating: addressObject.rating ? addressObject.rating : null,
              PhoneNumber: addressObject.international_phone_number
                ? addressObject.international_phone_number
                : "",
              // LinkedPropertyID: [vanityPropertyId], // Choose this or below
              LinkedProperty: isComponentShared
                ? linkedProperties
                : [propertyId], // Choose this or above
              LinkedMasterUser: userId,
              LinkedSharedContentProfile: isComponentShared
                ? SharedContentProfileId
                : null,
              ContentType: "place",
              CategoryLabel: CategoryLabel,
              DetailLabel: detailLabelString,
              PlaceTypeLabel: addressObject.types
                ? addressObject.types[0]
                : CategoryLabel,
              ListRank: customPlaceContent ? lastListRank + 1 : 0,
            };
            //  this.setState((prevState) => ({
            //   lat: addressObject.geometry.location.lat(),
            //   lng: addressObject.geometry.location.lng(),
            //   payload: this.state.payload
            //     ? [...prevState.payload, createContentPayload]
            //    : [createContentPayload]
            // }));

            //    console.log("createContentPayload", createContentPayload)
            // createContent here
            // this.handleStateLift(false);
            this.props.createContent(
              createContentPayload,
              ItemLabel,
              propertyId,
              SharedContentProfileId,
              userId
            );
          }

          //  if (this.state.payload === null) {
          //    this.setState(() => ({
          //      lat: addressObject.geometry.location.lat(),
          //      lng: addressObject.geometry.location.lng(),
          //     payload: [createContentPayload]
          //    }));
          //  }

          // if (this.state.payload !== null && !isNewContent) {
          if (!isNewContent) {
            this.setState(() => ({
              errorMessage: true,
            }));
            //   this.props.resetContentChange(true);
            setTimeout(
              function () {
                this.setState({ errorMessage: false });
              }.bind(this),
              2000
            );
          }
          this.autocomplete.addListener(
            "place_changed",
            (document.getElementById(`${ItemLabel}-autocomplete`).value = "")
          );
        }
      });
  };

  render() {
    // console.log("this.state.payload", this.state.payload);
    const { ItemLabel, InputLabelA } = this.props;

    return (
      <>
        <div className="infoitem-form-inner-container-full">
          <div style={{ height: "30px" }}></div>
          <label className="infoitem-form-label"> {InputLabelA} </label>
          <Script
            url={`https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places`}
            onLoad={this.handleScriptLoad}
          />
          <input
            className="infoitem-text-input"
            id={`${ItemLabel}-autocomplete`}
            placeholder="Enter place name or address and select from list"
            defaultValue=""
            value={this.state.name}
            required
          />
        </div>

        {this.state.errorMessage && (
          <div className="infoitem-detail-container">
            <div className="infoitem-form-error-message-left-align">
              This place has already been added (maybe within another category).
              Please try again.
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  createContent: (
    contentInfo,
    ItemLabel,
    propertyId,
    SharedContentProfileId,
    userId
  ) =>
    dispatch(
      createContent(
        contentInfo,
        ItemLabel,
        propertyId,
        SharedContentProfileId,
        userId
      )
    ),
});

export default connect(null, mapDispatchToProps)(InfoItemPlacesSearch);
