import React from "react";
import SharedPropertyContentPage from "../pages/SharedPropertyContentPage";
import Loading from "./Loading";
import { connect } from "react-redux";
import { getCustomContent } from "../redux/actions/userActions";
import { getSharedContent } from "../redux/actions/userActions";
import { setSharedContentLoaded } from "../redux/actions/userActions";
import { setCustomContentLoaded } from "../redux/actions/userActions";
import { getLinkedProperties } from "../redux/actions/userActions";
import { setLinkedPropertiesLoaded } from "../redux/actions/userActions";

class SharedContentHomeLoader extends React.Component {
  componentDidMount = () => {
    window.scrollTo(0, 0);
    const {
      //  PropertyId,
      //  CustomContentPropertyId,
      SharedPropertyContentProfileId,
      SharedContentLoadedId,
      ItemLabel,
    } = this.props;

    // console.log("SharedPropertyContentProfileId", SharedPropertyContentProfileId)
    //console.log("SharedContentLoadedId", SharedContentLoadedId)

    if (SharedPropertyContentProfileId === SharedContentLoadedId) {
      this.props.setSharedContentLoaded(true, SharedPropertyContentProfileId);
    }
    if (SharedPropertyContentProfileId !== SharedContentLoadedId) {
      this.props.setSharedContentLoaded(false, SharedPropertyContentProfileId);
      this.props.getSharedContent(SharedPropertyContentProfileId, ItemLabel);
    }
  };

  render() {
    const {
      SaveStatus,
      userData,
      companyData,
      managerData,
      propertyData,
      systemContent,
      systemCategories,
      customContent,
      //  CustomContentLoaded,
      //  linkedPropertiesLoaded,
      PropertyId,
      SharedPropertyContentProfileId,
      sharedContent,
      SharedContentLoaded,
    } = this.props;

    const propertyId =
      this.props.location.state && this.props.location.state.propertyId
        ? this.props.location.state.propertyId
        : PropertyId;

    //  console.log("PropertyId", PropertyId);
    //  console.log("CustomContentLoaded", CustomContentLoaded);
    //  console.log("linkedPropertiesLoaded", linkedPropertiesLoaded);
    //  console.log("propertyData", propertyData);

    if (!sharedContent || !SharedContentLoaded) {
      return <Loading LoadingCanvas={"loading-page-header-canvas"}/>;
    }

    if (sharedContent && SharedContentLoaded) {
      return (
        <SharedPropertyContentPage
          {...this.props}
          userData={userData}
          companyData={companyData}
          managerData={managerData}
          propertyData={propertyData}
          systemContent={systemContent}
          systemCategories={systemCategories}
          customContent={customContent}
          SaveStatus={SaveStatus}
          PropertyId={propertyId}
          SharedPropertyContentProfileId={SharedPropertyContentProfileId}
          sharedContent={sharedContent}
        />
      );
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomContent: (propertyId) => dispatch(getCustomContent(propertyId)),
    getSharedContent: (SharedPropertyContentProfileId, ItemLabel) =>
      dispatch(getSharedContent(SharedPropertyContentProfileId, ItemLabel)),
    setSharedContentLoaded: (payload, SharedPropertyContentProfileId) =>
      dispatch(setSharedContentLoaded(payload, SharedPropertyContentProfileId)),
    setCustomContentLoaded: (payload, propertyId) =>
      dispatch(setCustomContentLoaded(payload, propertyId)),
    setLinkedPropertiesLoaded: (payload) =>
      dispatch(setLinkedPropertiesLoaded(payload)),
    getLinkedProperties: (ItemLabel, userId) =>
      dispatch(getLinkedProperties(ItemLabel, userId)),
  };
};

export default connect(null, mapDispatchToProps)(SharedContentHomeLoader);
