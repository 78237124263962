import React from "react";
import ReactGA from "react-ga4";
import { appConfig } from "../data/appConfig";
import "../styles/DisplayCanvas.css";
import "../styles/DashboardShared.css";
import "../styles/ButtonBanners.css";
import AffiliatesStats from "../components/AffiliatesStats";
import AffiliatesProfile from "../components/AffiliatesProfile";

import LinkIcon from "../icons/LinkIcon";
import CheckmarkIcon from "../icons/CheckmarkIcon";

export default class AffiliatesHomePage extends React.Component {
  state = {
    linkCopied: false,
    referralUrl: "",
  };
  componentDidMount = () => {
    window.scrollTo(0, 0);
    const { userEmailId, userData } = this.props;
    ReactGA.send({
      hitType: "pageview",
      page: "/",
      title: `${userEmailId} - Affiliates Dashboard Home`,
    });
    this.setState({
      referralUrl: `${appConfig.referralUrl}?ref=${userData.ReferrerId}`,
    });
  };

  onLinkCopy = () => {
    navigator.clipboard.writeText(this.state.referralUrl);
    this.setState({ linkCopied: true });
    setTimeout(() => this.setState({ linkCopied: false }), 3000);
  };

  render() {
    const { userData } = this.props;

    const { referralUrl } = this.state;

    return (
      <>
        <div className="canvas-with-header-and-footer">
          <div className="canvas-with-header-and-footer-inner-container">
            <div style={{ height: "30px" }}></div>
            <div className="h1-header-large">{`Welcome, ${userData.UserFirstname}`}</div>

            <div className="url-callout-banner">
              <div className="url-callout-banner-header">
                👇 <strong>Share Your Affiliate Link</strong> 👇
              </div>

              <a
                className="url-callout-banner-value"
                id="infopage-link"
                href={referralUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {referralUrl}
              </a>

              <button
                className="referral-link-button"
                onClick={this.onLinkCopy}
              >
                {!this.state.linkCopied && (
                  <>
                    <div className="referral-link-label">Copy link</div>

                    <LinkIcon className="referral-link-box-icon" />
                  </>
                )}

                {this.state.linkCopied && (
                  <>
                    <div className="referral-link-label">
                      Link copied to clipboard
                    </div>
                    <CheckmarkIcon className="referral-link-box-icon" />
                  </>
                )}
              </button>
            </div>

            <div className="section-break-wrapper">
              <div className="section-break"></div>
            </div>

            <AffiliatesStats
              SectionHeading={"Account Details"}
              {...this.props}
            />

            <div className="section-break-wrapper">
              <div className="section-break"></div>
            </div>

            <AffiliatesProfile
              SectionHeading={"Profile & Payment Details"}
              MasterAccount={userData.UserMasterAccount}
              {...this.props}
            />

            <div className="section-break-wrapper">
              <div className="section-break"></div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
