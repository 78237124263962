import React from "react";
import {
  googlePlacePhotoApiRootUrl,
  googlePlacesApiKey,
  workflowApiRoot,
  apiSecretToken,
} from "../constants.js";
import "../styles/ListItemImage.css";

export default class ListItemImage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      didLoad: false,
      imageError: false,
      googlePhotoRef: this.props.GooglePhotoRef
        ? this.props.GooglePhotoRef
        : "",
      googlePlaceId:
        this.props.ItemData && this.props.ItemData.GooglePlaceID
          ? this.props.ItemData.GooglePlaceID
          : "",
      itemId:
        this.props.ItemData && this.props.ItemData._id
          ? this.props.ItemData._id
          : "",
    };
  }

  onLoad = () => {
    this.setState({
      didLoad: true,
      imageError: false,
    });
  };

  onImageError = () => {
    const { ItemData } = this.props;
    this.setState({
      imageError: true,
    });
    this.refreshGooglePlaceDetails(ItemData.GooglePlaceId, ItemData._id);
  };

  refreshGooglePlaceDetails = (googlePlaceId, contentId) => {
    fetch(`${workflowApiRoot}refresh-place-details`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${apiSecretToken}`,
      },
      body: JSON.stringify({
        place_id: googlePlaceId,
        contentId: contentId,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("refreshGooglePlaceDetails response", data.response);
        this.setState({
          googlePhotoRef: data.response.photoReference,
          imageError: false,
        });
      });
  };

  //shouldComponentUpdate = () => false;

  shouldComponentUpdate(prevProps, prevState) {
    return (
      prevProps.DetailImage !== this.props.DetailImage ||
      prevProps.GooglePhotoRef !== this.props.GooglePhotoRef ||
      prevState.imageError !== this.state.imageError
    );
  }

  render() {
    const { CategoryImage, DetailImage, AltText, GooglePhotoRef } = this.props;
    // Set Filenames so "null"s can be filtered out
    //  console.log("render");
    const detailImageFilename = DetailImage
      ? DetailImage.substring(DetailImage.lastIndexOf("/") + 1)
      : "";
    const categoryImageFilename = CategoryImage
      ? CategoryImage.substring(CategoryImage.lastIndexOf("/") + 1)
      : "";

    // Set Image source
    var ImageSource = null;
    if (DetailImage && detailImageFilename !== "null") {
      ImageSource = DetailImage;
    }
    if (
      (!DetailImage || detailImageFilename === "null") &&
      CategoryImage &&
      categoryImageFilename !== "null"
    ) {
      ImageSource = CategoryImage;
    }

    const ImageType = ImageSource
      ? ImageSource.substring(ImageSource.lastIndexOf(".") + 1)
      : "";

    var OuterWrapperStyle = "menu-item-image-outer-wrapper-loading";
    if (this.state.didLoad && ImageType === "svg") {
      OuterWrapperStyle = "menu-item-image-icon-outer-wrapper";
    }
    if (this.state.didLoad && ImageType !== "svg") {
      OuterWrapperStyle = "menu-item-image-outer-wrapper";
    }

    var InnerWrapperStyle = "menu-item-image-inner-wrapper-loading";
    if (this.state.didLoad && ImageType === "svg") {
      InnerWrapperStyle = "menu-item-image-icon-inner-wrapper";
    }
    if (this.state.didLoad && ImageType !== "svg") {
      InnerWrapperStyle = "menu-item-image-inner-wrapper";
    }

    var ImageStyle = "menu-item-image-loading menu-item-image-loading-animate";
    if (this.state.didLoad && ImageType === "svg") {
      ImageStyle = "menu-item-image-icon";
    }
    if (this.state.didLoad && ImageType !== "svg") {
      ImageStyle = "menu-item-image";
    }

    if (ImageSource) {
      return (
        <div className={OuterWrapperStyle}>
          <div className={InnerWrapperStyle}>
            <img
              className={ImageStyle}
              // src={ImageSource}
              src={`${ImageSource}?width=105`}
              alt={AltText}
              onLoad={this.onLoad}
            />
          </div>
        </div>
      );
    }

    if (!ImageSource && GooglePhotoRef) {
      return (
        <div className={OuterWrapperStyle}>
          <div className={InnerWrapperStyle}>
            <img
              className={ImageStyle}
              src={`${googlePlacePhotoApiRootUrl}maxwidth=105&photoreference=${GooglePhotoRef}&key=${googlePlacesApiKey}`}
              alt={AltText}
              onLoad={this.onLoad}
              onError={this.onImageError}
            />
          </div>
        </div>
      );
    }
    return (
      <>
        <div className="menu-item-image-outer-wrapper">
          <div className="menu-item-image-text-container">
            <div className="menu-item-image-text">Awaiting Image</div>
          </div>
        </div>
      </>
    );
  }
}
