import React from "react";
import NavigationHeader from "../components/NavigationHeader";
import InfoItemEmail from "../components/InfoItemEmail";
import InfoItemPassword from "../components/InfoItemPassword";
import "../styles/DisplayCanvas.css";
import "../styles/InfoPage.css";

export default class LoginInfoPage extends React.Component {
  // Scroll to top on page load
  componentDidMount() {
    window.scrollTo(0, 0);
    // ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {
    const { SaveStatus, userData } = this.props;

    const currentPageTitle = "Login Details";

    return (
      <>
        <NavigationHeader
          {...this.props}
          previousPageTitle={"Dashboard"}
          previousPagePath={"/"}
          currentPageTitle={currentPageTitle}
        />
        <div className="canvas-with-header-navheader-and-footer">
          <div className="canvas-with-header-and-footer-inner-container">
            <div className="h1-header-large"> {currentPageTitle}</div>
            <div className="infopage-subheading">Update your login details</div>

            <div className="infoitem-container">
              <div className="section-break-wrapper">
                <div className="section-break"></div>
              </div>
            </div>

            <InfoItemEmail
              SaveStatus={SaveStatus}
              userId={userData._id}
              ItemLabel={"Email address"}
              ItemHeading={"Email address"}
              ItemDescription={"This is the email address you use to login"}
              InputLabelA={"Email address"}
              ValueA={userData.authentication.email.email}
              PayloadA={"new-email"}
              IsChecked={userData.authentication.email.email ? true : false}
              FalseText={"Not entered"}
            />

            <InfoItemPassword
              SaveStatus={SaveStatus}
              userId={userData._id}
              ItemLabel={"Password"}
              ItemHeading={"Password"}
              ItemDescription={"This is the password you use to login"}
              InputLabelA={"Current password"}
              ValueA={""}
              PayloadA={"current-password"}
              InputLabelB={"New password"}
              ValueB={""}
              PayloadB={"new-password"}
              IsChecked={true}
            />
          </div>
        </div>
      </>
    );
  }
}
